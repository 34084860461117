import React, {useEffect, useState, useRef} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Frontpage from './pages/frontpage.page';
import Project from './pages/project.page';
import Resume from './pages/resume.page';

function App() {

  const [menuVisible, toggleMenuVisible] = useState(false);
  const [rawPosts, setRawPosts] = useState([])
  const [posts, setPosts] = useState([])
  // const positions = [

  //   {x:70, y:70},
  //   {x:630, y:70},

  //   {x:100, y:230},
  //   {x:700, y:270},

  //   {x:650, y:430},

  //   {x:100, y:630},
  //   {x:700, y:670},

  //   {x:30, y:830},
  //   {x:630, y:830},

  //   {x:100, y:1030},
  //   {x:700, y:1070},

  //   {x:30, y:1330},
  //   {x:630, y:1330},

  //   {x:100, y:1630},
  //   {x:700, y:1670},
  // ]

  const positions = [

    {x:22, y:44},
    {x:61, y:56},

    {x:15, y:62},
    {x:85, y:77},

    {x:52, y:81},

    {x:5, y:103},
    {x:77, y:117},

    {x:25, y:120},
    {x:95, y:135},

    {x:62, y:142},
    {x:84, y:157},

    {x:39, y:163},
    {x:55, y:178},

    {x:10, y:180},
    {x:90, y:190},

    {x:12, y:210},
    {x:82, y:225},

    {x:22, y:232},
    {x:74, y:245},

    {x:50, y:250},
    {x:20, y:266},
  ]

    useEffect(() =>{ 
      let fetchContentQuery = `https://cream-on-chrome.com/wp-json/wp/v2/posts/?per_page=100`
      fetch(fetchContentQuery)
    .then(res => res.json())
    .then(content => setRawPosts(content));
    },[]);

useEffect(()=>{
  if (rawPosts.length == 0) return
  let newPositions = [...positions]
  //console.log()
  const updatedPosts = rawPosts.map(post=>{
    let newPositionNumber = Math.floor(Math.random()*newPositions.length)
    //console.log(newPositions[newPositionNumber].x);
    let position =  {x:0,y:0};
    let x = ((newPositions[newPositionNumber].x / 100) * window.innerWidth);
    if (window.innerWidth - x < 410){
      position.x = x - 400
    } else{
      position.x = x
    }
    position.y = (newPositions[newPositionNumber].y / 100) * window.innerHeight;
    // console.log(position)
    post.position = position
    newPositions.splice(newPositionNumber, 1);
    //console.log(newPositions)
    //post.position = newPosition
    //console.log(post)
    return post
  })
  setPosts(updatedPosts)
},[rawPosts])
    
// console.log(posts)
if (!posts) return <></>
  return (
    <Routes>
      <Route exact path="/" element={<Frontpage posts={posts}/>}/>
      <Route exact path="/resume" element={<Resume posts={posts} menuVisible={menuVisible} toggleMenuVisible={toggleMenuVisible}/>}/>
      <Route exact path="/:slug" element={<Project posts={posts} menuVisible={menuVisible} toggleMenuVisible={toggleMenuVisible}/>}/>
    </Routes>
  );
}

export default App;
