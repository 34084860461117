import React, {useEffect, useState, useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import {useMediaQuery} from 'react-responsive';
import './project.styles.scss';
import Footer from '../components/footer.component'
import MenuView from '../components/menu-view.component'

const Project=({menuVisible, toggleMenuVisible, posts})=>{
    console.log('project')

    const [pageContent, setPageContent] = useState(null);
    const [viewState, setViewState] = useState(1);
    const [title, setTitle] = useState(false)
    //const [menuVisible, toggleMenuVisible] = useState(false)
    let location = useLocation();
    const navigate = useNavigate();

    let slug = location.pathname.substring(1);

    let slugEdited = slug.replace('CreamOnChrome-prototype', '')
    console.log(slugEdited)

    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })

    useEffect(() =>{ 
        let fetchContentQuery = `https://cream-on-chrome.com/wp-json/wp/v2/posts?slug=${slug}`
        fetch(fetchContentQuery)
	    .then(res => res.json())
	    .then(content => setPageContent(content));

    },[slugEdited]);

    useEffect(()=>{
        console.log('reload', pageContent)
        if(!pageContent) setTitle(false)
        else{
            setTitle(null)
            setTimeout(() => {
                setTitle(true)
            }, 500);
            // setTitle(pageContent[0].title.rendered)
        }
    },[pageContent])

    const viewStateHandler =(el)=>{
        let state = 1;

        if (el == 'left'){
            switch (viewState){
                case 0: state = 1
                break;
                case 1: state = 0
                break;
            }
        }
        if (el == 'right'){
            switch (viewState){
                case 2: state = 1
                break;
                case 1: state = 2
                break;
            }
        }

        setViewState(state);
    }
    function createMarkup(render) {
        return {__html: render};
      }

      const returnxPos=()=>{
          if(isNarrow){
                switch (viewState){
                    case 0: return 0
                    break;
                    case 1: return -100
                    break;
                    case 2: return -200
                    break
                }
            }else{
                switch (viewState){
                    case 0: return 0
                    break;
                    case 1: return -60
                    break;
                    case 2: return -120
                    break
                }
            }
      }

      const returnNavigationPos =(el)=>{
        
        if (el == 'left'){
            switch (viewState){
                case 0: return '70vw'
                break;
                case 1: return '10px'
                break;
                default: return '10px'
            }
        }
        if (el == 'right'){
            switch (viewState){
                case 2: return '-70vw'
                break;
                case 1: return '-10px'
                break;
                default: return '-10px'
            }
        }
      }

      const returnNavigationFloat =(el)=>{
        
        if (el == 'left'){
            switch (viewState){
                case 0: return 'right'
                break;
                case 1: return 'left'
                break;
                default: return 'left'
            }
        }
        if (el == 'right'){
            switch (viewState){
                case 2: return 'left'
                break;
                case 1: return 'right'
                break;
                default: return 'right'
            }
        }
      }

// console.log(pageContent)
if (!pageContent) return <></>
    return(
        <div className="project">
            {menuVisible ? <MenuView posts={posts} toggleMenuVisible={toggleMenuVisible} /> :''}
            <div className="project-navigation">
                
                {isNarrow ?
                    <div className="mobile-page-navigation">
                        <div className="round-button orange" onClick={()=>{toggleMenuVisible(!menuVisible)}}>
                            MENU
                        </div>
                                {viewState == 1 && pageContent[0].acf.singleProjectMethodology ?
                                    <div className={`project-navigation-left-mobile-button`} onClick={()=>viewStateHandler('left')}>
                                        METHODOLOGY
                                    </div>
                                :''}
                                {viewState == 2 ?
                                   <div className={`project-navigation-left-mobile-button`} onClick={()=>viewStateHandler('left')}>
                                        PROJECT
                                    </div>
                                :''}
                                
                                {viewState == 1 && pageContent[0].acf.singleProjectResearch ?
                                    <div className={`project-navigation-right-mobile-button`} onClick={()=>viewStateHandler('right')}>
                                    RESEARCH
                                    </div>
                                :''}
                                {viewState == 0 ?
                                <div className={`project-navigation-right-mobile-button`} onClick={()=>viewStateHandler('right')}>
                                    PROJECT
                                </div>
                                :''}

                        </div>
                    :
                    ''}

                {!isNarrow ? <>
                <div className="project-navigation-top">
                    <div className="logo-button" onClick={()=>{navigate('/')}}>
                        CREAM
                    </div>
                    <div className="logo-button on-button-wrapper" onClick={()=>{navigate('/')}}>
                        <div className="on-button">
                        ON
                        </div>
                    </div>
                    <div className="logo-button" onClick={()=>{navigate('/')}}>
                        CHROME
                    </div>

                    <div className="project-navigation-top-right">
                        <div className="round-button orange" onClick={()=>{toggleMenuVisible(!menuVisible)}}>
                            MENU
                        </div>
                        <div className="round-button" onClick={()=>{navigate('/resume')}}>
                            ABOUT
                        </div>
                    </div>
                </div>
                <div className={`project-navigation-left ${pageContent[0].acf.singleProjectMethodology ? 'display': 'hidden'}`} style={{transform:`translatex(${returnNavigationPos('left')})`}}>
                    {viewState < 2 ?
                        <div className="project-navigation-left-button" onClick={()=>viewStateHandler('left')}>
                            {viewState == 1 ?
                                <>
                                M<br/>
                                E<br/>
                                T<br/>
                                H<br/>
                                O<br/>
                                D<br/>
                                O<br/>
                                L<br/>
                                O<br/>
                                G<br/>
                                Y<br/>
                                </>
                            :''}
                            {viewState == 0 ?
                            <>
                                P<br/>
                                R<br/>
                                O<br/>
                                J<br/>
                                E<br/>
                                C<br/>
                                T<br/>
                            </>
                            :''}
                     </div>
                     :''}
                </div>
                <div className={`project-navigation-right ${pageContent[0].acf.singleProjectResearch ? 'display': 'hidden'}`} style={{transform:`translatex(${returnNavigationPos('right')})`}}>
                    {viewState > 0 ?
                        <div className="project-navigation-right-button" onClick={()=>viewStateHandler('right')}>

                            {viewState == 1 ?
                                <>
                                R<br/>
                                E<br/>
                                S<br/>
                                E<br/>
                                A<br/>
                                R<br/>
                                C<br/>
                                H<br/>
                                </>
                            :''}
                            {viewState == 2 ?
                                <>
                                P<br/>
                                R<br/>
                                O<br/>
                                J<br/>
                                E<br/>
                                C<br/>
                                T<br/>
                                </>
                            :''}
                            
                            
                        </div>
                    :''}
                </div>
                </>
                :''}
            </div>
            <div className="project-content-wrapper"
             style={{transform:`translatex(${returnxPos()}vw)`}}
            >
                <div className={`project-content-left ${viewState !== 0 ? 'faded' : ''}`}>
                    <div className="left-content-wrapper">
                        <div className="left-title">
                                Methodology
                        </div>
                    {pageContent[0].acf.singleProjectMethodology ?
                        <div className="left-image">
                            <img src={pageContent[0].acf.singleProjectMethodology} />
                        </div>
                    :''}
                        
                    </div>
                </div>
                <div className={`project-content-middle ${viewState !== 1 ? 'faded' : ''}`}>
                    <div className="middle-content-wrapper">
                        <div className={`middle-title ${title ? 'title-visible' : ''}`}>
                            {pageContent[0].title.rendered}
                        </div>
                        <div className="middle-content">
                            <div dangerouslySetInnerHTML={createMarkup(pageContent[0].content.rendered)} />
                        </div>
                        <Footer/>
                    </div>
                
                </div>
                <div className={`project-content-right ${viewState !== 2 ? 'faded' : ''}`}>
                    <div className="right-content-wrapper">
                        <div className="right-title-wrapper">
                            <div className="right-title">
                                {pageContent[0].acf.singleProjectResearchTitle}
                            </div>
                            <div className="right-subtitle">
                                {pageContent[0].acf.singleProjectEditedBy}
                            </div>
                        </div>
                        <div className="right-content">
                            <div dangerouslySetInnerHTML={createMarkup(pageContent[0].acf.singleProjectResearch)} />
                            {pageContent[0].acf.singleProjectFootnotes ?
                            <div className="right-content-footnotes">
                                <div dangerouslySetInnerHTML={createMarkup(pageContent[0].acf.singleProjectFootnotes)} />
                            </div>
                            :
                            ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Project