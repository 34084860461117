import React, {useEffect, useState, useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import {useMediaQuery} from 'react-responsive';

import './frontpage.styles.scss';

import DraggableElement from '../components/draggableElement.component.jsx'

const Frontpage=({posts})=>{

    // const [pageContent, setPageContent] = useState(null);
    const [frontPageContent, setFrontPageContent] = useState(null);
    const [isDragged, toggleIsDragged] = useState(null);

    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })
    const navigate = useNavigate();

    useEffect(() =>{ 
        let fetchContentQuery = `https://cream-on-chrome.com/wp-json/wp/v2/pages/7` //7
        fetch(fetchContentQuery)
	    .then(res => res.json())
	    .then(content => setFrontPageContent(content));
    },[]);

    function createMarkup(render) {
        return {__html: render};
    }

// console.log(frontPageContent, posts)
if (!frontPageContent || posts.length == 0) return <></>
    return(
        <div className="frontpage">
            <div className="navigation-top">
                    <div className="logo-button" onClick={()=>{navigate('/resume')}}>
                        CREAM
                    </div>
                    <div className="logo-button on-button-wrapper" onClick={()=>{navigate('/resume')}}>
                        <div className="on-button">
                        ON
                        </div>
                    </div>
                    <div className="logo-button" onClick={()=>{navigate('/resume')}}>
                        CHROME
                    </div>
                    {!isNarrow ?
                        <div className="navigation-top-right">
                            <div className="round-button" onClick={()=>{navigate('/resume')}}>
                                ABOUT
                            </div>
                        </div>
                    :''}
                </div>
                <div className="frontpage-content">
                    <div dangerouslySetInnerHTML={createMarkup(frontPageContent.content.rendered)} />
                </div>
                <div className="frontpage-project-wrapper">
                    {posts.map(post=>{
                        // console.log(post)
                        if (isNarrow){
                            return <div key={post.id} className="frontpage-mobile-thumbnail" onClick={()=>{navigate(`/${post.slug}/`)}}>
                                    <div className="frontpage-mobile-title">
                                        {post.title.rendered}
                                    </div>
                                <img src={post.acf.singleProjectPreview1}/>
                                </div>
                        }else{
                            return <DraggableElement key={post.id} isDragged={isDragged} toggleIsDragged={toggleIsDragged} initPos={post.position} images={post.acf} title={post.title.rendered} slug={post.slug}/>
                        }
                    })}
                </div>
        </div>
    )
}

export default Frontpage