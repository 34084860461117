import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from 'react-router-dom'
import Draggable from 'react-draggable';

import './draggableElement.styles.scss';

const DraggableElement=({isDragged, toggleIsDragged, initPos, images, title, slug,toggleMenuVisible})=>{
   // console.log(initPos)

    const [pos, setPos] = useState(null);
    const [xPosImage, setxPosImage] = useState(null);
    const [deltaStart, setDeltaStart]=useState(null)

    const [offsetStart, setOffsetStart]=useState(null)

    const [hasInit, setHasInit] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        if (hasInit) return;
        //console.log('set', initPos)

        setPos(initPos)
        setDeltaStart(initPos)
        setxPosImage(-initPos.x)

        setHasInit(true)
    },[initPos])
    //const [isDragged,toggleIsDragged] =useState(false)

    const onDragHandler =(e)=>{
        // console.log(e.x)
        // console.log(deltaStart.x)

        // previous position minus movement?

        // regn ut hvor langt fra kanten man klikker
        // forskjellen mellom hvileposisjon og xoffset
            //helt til venstre = 0
            //helt til høyre = 400
    
        setxPosImage(-(e.x - offsetStart))
    }

    const startDragHandler =(e)=>{
        // console.log(pos)
        
        //console.log(pos.x - e.nativeEvent.offsetX)
        // console.log('xPosImage', xPosImage)
        // console.log('pos x', pos.x)
        toggleIsDragged(slug)
        setOffsetStart(e.nativeEvent.offsetX - pos.x)
        setDeltaStart({x:e.nativeEvent.x,y:e.nativeEvent.y})
    }

    const stopDragHandler =(e)=>{
        toggleIsDragged(null)
        const newPos = {x:pos.x + (e.x - deltaStart.x), y:pos.y + (e.y - deltaStart.y) }

         if((e.x - deltaStart.x) > -5 && (e.x - deltaStart.x) < 5 && (e.y - deltaStart.y) > -5 && (e.y - deltaStart.y) < 5){
            //go to link
            // if  toggleMenuVisible(false)
            console.log(toggleMenuVisible)
            if(toggleMenuVisible !== undefined){
                toggleMenuVisible(false)
            }
            navigate(`/${slug}`);
            setPos(newPos)
         }

        setPos(newPos)
    }
if (!hasInit) return <></>
    return(
            <Draggable
            // cancel="strong" 
            onStart={(e)=>startDragHandler(e)}
            onDrag={(e)=>onDragHandler(e)}
            onStop={(e)=>stopDragHandler(e)}
            //bounds={{top: calculateTopMapMargin(), left: calculateLeftMapMargin(), right: -200, bottom: -500}}
            //defaultPosition={initPos}
            position={pos}
            >
                <div className={`draggable-element ${isDragged == null ? '' : isDragged == slug ? 'dragged' : 'not-dragged'}`}>
                    <div className="draggable-title-wrapper">
                        <div className="draggable-title">
                            {title}
                        </div>
                    </div>
                    
                    <div className="draggable-images" style={{transform:`translate(${xPosImage}px)`}}>
                        <div className="draggable-image">
                            <img src={images.singleProjectPreview1}/>
                        </div>
                        <div className="draggable-image">
                            {images.singleProjectPreview2 ?
                            <img src={images.singleProjectPreview2}/>
                            :
                            <img src={images.singleProjectPreview1}/>
                            }
                        </div>
                        <div className="draggable-image">
                            {images.singleProjectPreview3 ?
                            <img src={images.singleProjectPreview3}/>
                            :
                            <img src={images.singleProjectPreview1}/>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
    )

}

export default DraggableElement