import React, {useEffect, useState, useRef} from "react";
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {useMediaQuery} from 'react-responsive';
import './resume.styles.scss';
import Footer from '../components/footer.component'
import MenuView from '../components/menu-view.component'

const Resume=({menuVisible, toggleMenuVisible, posts})=>{

    const [pageContent, setPageContent] = useState(null);
    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })

    const navigate = useNavigate();

    useEffect(() =>{ 
        let fetchContentQuery = `https://cream-on-chrome.com/wp-json/wp/v2/pages?slug=resume`
        fetch(fetchContentQuery)
	    .then(res => res.json())
	    .then(content => setPageContent(content));
    },[]);

    function createMarkup(render) {
        return {__html: render};
      }

console.log(pageContent)
if (!pageContent) return <></>
    return(
        <div className="resume">
                {menuVisible ? <MenuView posts={posts} toggleMenuVisible={toggleMenuVisible} /> :''}

                {isNarrow ?
                    <div className="resume-navigation-top">
                        <div className="round-button orange" onClick={()=>{toggleMenuVisible(!menuVisible)}}>
                            MENU
                        </div>
                    </div>
                    :
                    <div className="resume-navigation-top">
                        <div className="logo-button" onClick={()=>{navigate('/')}}>
                            CREAM
                        </div>
                        <div className="logo-button on-button-wrapper" onClick={()=>{navigate('/')}}>
                            <div className="on-button">
                            ON
                            </div>
                        </div>
                        <div className="logo-button" onClick={()=>{navigate('/')}}>
                            CHROME
                        </div>
                        <div className="resume-navigation-top-right">
                            <div className="round-button orange" onClick={()=>{toggleMenuVisible(!menuVisible)}}>
                                MENU
                            </div>
                        </div>
                    </div>
                    }
            <div className="resume-content-wrapper">

                    <div className="resume-title">
                        {pageContent[0].title.rendered}
                    </div>
                    <div className="resume-content">
                        <div dangerouslySetInnerHTML={createMarkup(pageContent[0].content.rendered)} />
                        <Footer/>
                    </div>
                    
                </div> 
        </div>
    )

}

export default Resume