import React, {useEffect, useState, useRef} from "react";

import './footer.styles.scss';

const Footer=()=>{

    const [footerContent, setFooterContent] = useState(null)
    useEffect(() =>{ 
        let fetchContentQuery = `https://cream-on-chrome.com/wp-json/wp/v2/pages?slug=footer-content`
        fetch(fetchContentQuery)
	    .then(res => res.json())
	    .then(content => setFooterContent(content));
    },[]);

    function createMarkup(render) {
        return {__html: render};
    }

if (!footerContent) return <></>
    return(
        <div className="footer-wrapper">
            <div dangerouslySetInnerHTML={createMarkup(footerContent[0].acf.footerInfo)} />
        </div>
    )

}

export default Footer