import React, {useEffect, useState, useRef} from "react";
import {useMediaQuery} from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom'

import './menu-view.styles.scss';

import DraggableElement from './draggableElement.component.jsx'

const MenuView=({toggleMenuVisible, posts})=>{
    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })
    const navigate = useNavigate();

    //const [pageContent, setPageContent] = useState(null);
    const [isDragged, toggleIsDragged] = useState(null);

if (!posts) return <></>
    return(
        <div className="menu-view-wrapper">
                <div className="menu-view-project-wrapper">
                {isNarrow ? <div className="round-button menu-button" onClick={()=>{toggleMenuVisible(false);navigate(`/resume`)}}>ABOUT</div>:''}
                    {posts.map(post=>{
                        if (isNarrow){
                            return <div key={post.id} className="menu-view-mobile-thumbnail" onClick={()=>{toggleMenuVisible(false);navigate(`/${post.slug}`)}}>
                                    <div className="menu-view-mobile-title">
                                        {post.title.rendered}
                                    </div>
                                <img src={post.acf.singleProjectPreview1}/>
                                </div>
                        }else{
                            return <DraggableElement key={post.id} isDragged={isDragged} toggleIsDragged={toggleIsDragged} initPos={post.position} images={post.acf} title={post.title.rendered} slug={post.slug} toggleMenuVisible={toggleMenuVisible}/>
                        }
                    })}
                </div>
        
        <div className="menu-view-background" onClick={()=>toggleMenuVisible(false)}></div>
        </div>
    )

}

export default MenuView